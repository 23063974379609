.privacy-policy {
    margin: 20px;
    font-family: Arial, sans-serif;

}

h1 {
    font-size: 24px;
    font-weight: bold;
    color: white;
}

h2 {
    /* font-size: 20px; */
    /* font-weight: bold; */
    color: white;
}

h3 {
    font-size: 18px;
    font-weight: bold;
    color: white;
}

p {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 10px;
    color: white;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* padding horizontal */
    padding-left: 10%;
    padding-right: 10%;
    margin-bottom: 60px;

}

/* Add more styles as needed for different elements */


.back-button {
    display: inline-block;
    text-decoration: none;
    font-weight: bold;
    color: white;
    position: absolute;
    left: 10px;
    top: 10px;
}

.back-button:hover {
    text-decoration: underline;
}