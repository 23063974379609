.app {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  background-color: #3c3c3c;
}

.app-header {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
  /* Make top right */
  position: absolute;
  top: 0;
  right: 0;
}


.footer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #3c3c3c;
  padding: 10px;
  color: white;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  gap: 10px;
}

.footer a {
  text-decoration: none;
  color: white;
  font-size: 0.8em;
}