

.heading {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2em;
    font-weight: bold;
    color: white;
    margin-bottom: 20px;
}

.container {
    min-height: 35vh;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
}

.banner {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #3c3c3c;
    padding: 10px;
    color: white;
    margin-bottom: 20px;
}