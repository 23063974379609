.form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 50%;
    color: white;
    background-color: #3c3c3c;
    margin-bottom: 20px;
}

/* Media query for smaller screens */
@media (max-width: 768px) {
    .form {
        width: 80%;
        /* Increase the width to 80% on smaller screens */
        padding-right: 10%;
    }
}